import type { FC, ReactNode } from 'react'
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components'

import media from 'styles/media'
import { themes } from 'styles/theme'
import darkTheme from 'styles/theme/colors/dark'

import FooterNew from './Footer'
import Header from './Header'

type HomeLayoutProps = {
	children: ReactNode
}

const HomeLayout: FC<HomeLayoutProps> = ({ children, ...props }) => (
	<ThemeProvider theme={themes.dark}>
		<Container {...props}>
			<GlobalStyle />
			<Header />
			<MainContent>{children}</MainContent>
			<FooterNew />
		</Container>
	</ThemeProvider>
)

const GlobalStyle = createGlobalStyle`
	body {
		overflow-x: hidden;
		background-color: ${darkTheme.darkBackground};
		color: ${darkTheme.text.value};
	}
`

const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	overflow: hidden;
`

const MainContent = styled.main`
	flex: 1;
	position: relative;
	padding: 40px;
	overflow: scroll;
	border-bottom: ${(props) => props.theme.colors.selectedTheme.landing.border};
	${media.lessThan('lg')`
		padding: 20px;
  `}
`

export default HomeLayout
