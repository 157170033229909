import { type ComponentProps, forwardRef } from 'react'

import Button from 'components/Button'

const RefButton = forwardRef<HTMLDivElement, ComponentProps<typeof Button>>((props, ref) => (
	<div ref={ref}>
		<Button noOutline size="medium" {...props}>
			{props.children}
		</Button>
	</div>
))

export default RefButton
